<template>
  <footer>
    <router-link to="/" class="menu-link">
      <span class="icon">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect opacity="0.32" x="1.25" y="3" width="22" height="18" rx="4" fill=""></rect>
          <rect x="2.25" y="4" width="20" height="16" rx="4" stroke="#A6A6A6" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round"></rect>
          <path d="M12.75 12C12.75 10.3431 14.0931 9 15.75 9H21.75V15H15.75C14.0931 15 12.75 13.6569 12.75 12V12Z"
            stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path
            d="M15.75 12C15.75 12.2761 15.9739 12.5 16.25 12.5C16.5261 12.5 16.75 12.2761 16.75 12C16.75 11.7239 16.5261 11.5 16.25 11.5C15.9739 11.5 15.75 11.7239 15.75 12Z"
            stroke="#A6A6A6" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </span>
      <span class="name">{{ $t('menu.home') }}</span>
    </router-link>
    <router-link to="/tasks" class="menu-link">
      <span class="icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="Style=Line">
            <path id="Vector"
              d="M8 4H7.2002C6.08009 4 5.51962 4 5.0918 4.21799C4.71547 4.40973 4.40973 4.71547 4.21799 5.0918C4 5.51962 4 6.08009 4 7.2002V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H8M8 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H8M8 4V20M12 11H16M12 8H16"
              stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </g>
        </svg>
      </span>
      <span class="name">{{ $t('menu.tasks') }}</span>
    </router-link>
    <router-link to="/friends" class="menu-link">
      <span class="icon">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.5 20C17.5 18.3431 15.2614 17 12.5 17C9.73858 17 7.5 18.3431 7.5 20M21.5 17.0004C21.5 15.7702 20.2659 14.7129 18.5 14.25M3.5 17.0004C3.5 15.7702 4.7341 14.7129 6.5 14.25M18.5 10.2361C19.1137 9.68679 19.5 8.8885 19.5 8C19.5 6.34315 18.1569 5 16.5 5C15.7316 5 15.0308 5.28885 14.5 5.76389M6.5 10.2361C5.88625 9.68679 5.5 8.8885 5.5 8C5.5 6.34315 6.84315 5 8.5 5C9.26835 5 9.96924 5.28885 10.5 5.76389M12.5 14C10.8431 14 9.5 12.6569 9.5 11C9.5 9.34315 10.8431 8 12.5 8C14.1569 8 15.5 9.34315 15.5 11C15.5 12.6569 14.1569 14 12.5 14Z"
            stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </span>
      <span class="name">{{ $t('menu.friends') }}</span>
    </router-link>
    <router-link to="/leaderboard" class="menu-link">
      <span class="icon">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12 14V17M12 14C9.58104 14 7.56329 12.2822 7.10002 10M12 14C14.419 14 16.4367 12.2822 16.9 10M17 5H19.75C19.9823 5 20.0985 5 20.1951 5.01921C20.5918 5.09812 20.9019 5.40822 20.9808 5.80491C21 5.90151 21 6.01767 21 6.25C21 6.94698 21 7.29547 20.9424 7.58527C20.7056 8.77534 19.7753 9.70564 18.5853 9.94236C18.2955 10 17.947 10 17.25 10H17H16.9M7 5H4.25C4.01767 5 3.90151 5 3.80491 5.01921C3.40822 5.09812 3.09812 5.40822 3.01921 5.80491C3 5.90151 3 6.01767 3 6.25C3 6.94698 3 7.29547 3.05764 7.58527C3.29436 8.77534 4.22466 9.70564 5.41473 9.94236C5.70453 10 6.05302 10 6.75 10H7H7.10002M12 17C12.93 17 13.395 17 13.7765 17.1022C14.8117 17.3796 15.6204 18.1883 15.8978 19.2235C16 19.605 16 20.07 16 21H8C8 20.07 8 19.605 8.10222 19.2235C8.37962 18.1883 9.18827 17.3796 10.2235 17.1022C10.605 17 11.07 17 12 17ZM7.10002 10C7.03443 9.67689 7 9.34247 7 9V4.57143C7 4.03831 7 3.77176 7.09903 3.56612C7.19732 3.36201 7.36201 3.19732 7.56612 3.09903C7.77176 3 8.03831 3 8.57143 3H15.4286C15.9617 3 16.2282 3 16.4339 3.09903C16.638 3.19732 16.8027 3.36201 16.901 3.56612C17 3.77176 17 4.03831 17 4.57143V9C17 9.34247 16.9656 9.67689 16.9 10"
            stroke="#A6A6A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class="name">{{ $t('menu.raiting') }}</span>
    </router-link>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped lang="scss">
footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #150615;
  height: 80px;
  justify-content: center;
  align-items: center;

  .menu-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    padding: 0 20px;
    height: 100%;
    background: transparent;
    padding-bottom: 15px;
    transition: all 0.15s ease-in-out;
    color: grey;

    img {
      width: 20px;
      opacity: 0.7;
      transition: all 0.15s ease-in-out;
    }

    @media (max-width: 386px) {
      font-size: 12px;
    }

    &.router-link-exact-active {
      color: #fff;

      svg {

        path,
        rect {
          stroke: white;
        }
      }
    }
  }
}
</style>
