<template>
  <header class="centered">
    <div class="avatar flex centered">
      <span>{{ $store.getters.avatarPlaceholder }}</span>
      <img :src="`https://t.me/i/userpic/160/${$store.state.user.telegramLogin}.jpg`" />
    </div>
    <span class="my-wallet">{{ $t('my_wallet') }}</span>

    <Button color="purple" class="withdraw-btn" @on-click="open">Claim $SPERM</Button>
    <!-- <Button color="purple" class="withdraw-btn" @on-click="open">{{
      $t('withdraw') }}</Button>
    <span class="timer" style="color: grey;">{{ timer }}</span> -->

    <!-- <Button color="purple" class="withdraw-btn" @on-click="openGift">{{
      $t('open_gift') }} (x{{ $store.state.user.gifts }})</Button>
    <span class="gift-promo">1 приглашенный друг = 1 подарок</span>

    <div class="subscribe" v-if="!$store.state.user.isSubscribed">
      <span>Подписка на группу дает вам <b>1 подарок</b></span>
      <Button color="purple" class="withdraw-btn" @on-click="subscribe">Подписаться</Button>
    </div>

    <Gift @opened="showPrize" v-if="showGift" /> -->

    <div class="wallets-wrapper">
      <h4>{{ $t('coins') }}</h4>
      <div class="wallets">
        <div class="wallet">
          <div class="info">
            <img src="@/assets/images/sperm.jpg" />
            <div class="description">
              <span class="name">Sperm</span>
              <span class="value">{{ $store.getters.bubbleBalance }} SPERM</span>
            </div>
          </div>
          <span class="bal"></span>
        </div>
        <div class="wallet">
          <div class="info">
            <img src="@/assets/images/ton.png" />
            <div class="description">
              <span class="name">TON</span>
              <span class="value">{{ $store.getters.tonBalance }} TON</span>
            </div>
          </div>
          <span class="bal"></span>
        </div>
        <div class="wallet">
          <div class="info">
            <img src="@/assets/images/not.png" />
            <div class="description">
              <span class="name">Notcoin</span>
              <span class="value">{{ $store.getters.notBalance }} NOT</span>
            </div>
          </div>
          <span class="bal"></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// import { toNano } from '@ton/ton'
import axios from 'axios';
import Button from './Button.vue';
import Gift from './Gift.vue';
import { numberWithCommas } from '../utility';

export default {
  name: 'Header',
  components: { Button, Gift },
  data() {
    return {
      timer: '',
      interval: null,
      showGift: false,
      freeze: false
    }
  },
  methods: {
    numberWithCommas,
    async open() {
      if (!this.$store.state.tonConnectUI.connected) {
        await this.$store.state.tonConnectUI.connectWallet();
      }

      // const body = beginCell()
      //   .storeUint(0, 32)
      //   .storeStringTail(this.$store.state.user.telegramId.toString())
      //   .endCell();

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: "UQB_BXs5BrnBd6I4ijo5EvpT__vt7YZCuQ5NwQ8YRqL2h_sV",
            amount: "1000000000",
            // payload: body.toBoc().toString("base64")
          }
        ]
      }

      const createdTransaction = await this.$store.state.tonConnectUI.sendTransaction(transaction, {
        modals: "all",
        notifications: "all",
        returnStrategy: "none"
      }).catch(e => {
        console.log(e)
      })

      if (createdTransaction) {
        alert('Ожидайте SPERM на своем кошельке в течение 24 часов')
      }
    },
    subscribe() {
      if (this.freeze) {
        return
      }
      this.freeze = true
      window.Telegram.WebApp.openTelegramLink('https://t.me/+XhpMy-iV_J83ZGYy')
      setTimeout(async () => {
        const { data } = await axios.get('/user/subscribe')
        if (data === true) {
          this.$store.commit('increaseGift')
          this.$store.commit('setSubscribe')
          alert('Вы получили подарок!')
        } else {
          alert('Вы не подписались на группу')
        }
        this.freeze = false
      }, 3000)
    },
    openGift() {
      if (this.$store.state.user.gifts < 1) {
        alert('У вас нет подарков - приглашайте друзей')
        return
      }

      this.showGift = true
    },
    async showPrize() {
      const { data } = await axios.get('/user/open-gift')
      alert(data)
      this.$store.commit('getBalance')
      this.$store.commit('decreaseGifts')
      this.showGift = false
    },
    getTime() {
      const start = 1722596150
      const now = new Date().getTime() / 1000
      this.timer = this.parseTime(start - now)
    },
    parseTime(time) {
      const sec_num = parseInt(time, 10);
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (hours < 10) { hours = "0" + hours; }
      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return hours + ':' + minutes + ':' + seconds;
    }
  },
  mounted() {
    this.interval = setInterval(() => this.getTime(), 1000)
    this.getTime()
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: column;
  gap: 15px 0;


  .subscribe {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    border-radius: 5px;
    padding: 15px;
    background: rgb(158, 158, 158, 0.2);
    align-items: center;
    margin: 20px 0;

    .withdraw-btn {
      margin-top: 10px;
      border-radius: 20px;
      background: #2d7fc5;
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #6767f7;
    overflow: hidden;
    position: relative;

    span {
      font-size: 26px;
    }

    img {
      max-width: 100%;
      position: absolute;
    }
  }

  .my-wallet {
    font-size: 16px;
    color: #e4e4e4;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 280px;
    white-space: nowrap;
  }

  .balance {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
  }


  .withdraw-btn {
    width: 100%;
    font-size: 18px;
    padding: 15px 25px;
  }

  .wallets-wrapper {
    width: 100%;
    margin-top: 10px;
    background: #6f6f6f26;
    border-radius: 5px;
    padding: 15px;

    h4 {
      text-align: left;
    }
  }

  .wallets {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    gap: 20px 0;

    .wallet {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .info {
        display: flex;

        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background: linear-gradient(to top, rgb(131, 66, 66), rgb(32, 32, 167));
        }

        .description {
          display: flex;
          margin-left: 10px;
          justify-content: flex-start;
          flex-direction: column;
          text-align: left;

          .name {
            font-weight: 600;
            color: #fff;
          }

          .value {
            color: #a6a6a6;
          }

          @media (max-width: 384px) {
            font-size: 14px;
          }
        }
      }

      .bal {
        font-weight: 700;
      }
    }
  }
}
</style>
