<template>
  <div class="modal flex centered">
    <div class="close-bg" @click="$emit('closeModal')"></div>
    <Transition name="slide-up">
      <div class="modal-body" v-if="animate">
        <span class="modal-text flex centered">{{ $t('intro_bonus') }}</span>
        <Button color="purple" class="thanks-btn" @on-click="() => $emit('closeModal')">{{ $t('thanks') }}</Button>
      </div>
    </Transition>
  </div>
</template>

<script>
import Button from './Button.vue'

export default {
  name: 'Modal',
  components: { Button },
  methods: {},
  data() {
    return {
      animate: false,
    }
  },
  mounted() {
    this.animate = true
  }
}
</script>

<style lang="scss">
.modal-header {
  margin-bottom: 30px;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(100%);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 1000;

  .close-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
  }

  .modal-body {
    position: absolute;
    width: 100%;
    background: #000;
    z-index: 1002;
    padding: 30px 15px;
    border-top: 1px solid #540e54;
    border-bottom: 1px solid #540e54;

    @media screen and (max-height: 645px) {
      padding: 30px 15px 20px 15px;
    }

    .modal-text {
      margin-bottom: 20px;
    }

    .modal-header {
      .title {
        font-size: 26px;
        font-weight: 600;

        @media screen and (max-height: 645px) {
          font-size: 22px;
        }
      }

      .description {
        color: grey;
      }

      .close {
        display: flex;
        position: absolute;
        right: 30px;
        top: 30px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        font-weight: 600;
        color: #929292;
      }
    }
  }
}
</style>
