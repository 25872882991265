import { createStore } from 'vuex'
import axios from "axios";
import { numberWithCommas } from '../utility';

export default createStore({
  state() {
    return {
      authToken: '',
      siteLoading: true,
      user: {},
      config: {},
      showIntroModal: false,

      tasks: [],

      tonConnectUI: null,

      selectedTask: null,
      isWithdrawShow: false
    }
  },
  getters: {
    usdBalance(state) {
      return state.user ? (state.user.balance / 1000).toFixed(2) : '0.00'
    },
    bubbleBalance(state) {
      return state.user ? numberWithCommas((state.user.balance), false) : '0'
    },
    notBalance(state) {
      return state.user.notBalance;
    },
    tonBalance(state) {
      return state.user.tonBalance;
    },
    avatarPlaceholder(state) {
      return state.user ? state.user.username.substring(0, 1) : 'A'
    }
  },
  mutations: {
    setSelectedTask(state, task) {
      state.selectedTask = task
    },
    async getBalance() {
      const { data } = await axios.get('/user/balance')
      this.commit('setBalance', data)
    },
    hideIntroModal(state) {
      state.showIntroModal = false
    },
    setSiteLoading(state, loading) {
      state.siteLoading = loading
    },
    setAuthToken(state, token) {
      state.authToken = token
    },
    setUser(state, user) {
      state.user = user
    },
    setConfig(state, config) {
      state.config = config
    },
    setBalance(state, { balance, notBalance, tonBalance }) {
      state.user.balance = balance
      state.user.notBalance = notBalance
      state.user.tonBalance = tonBalance
    },
    increaseGift(state) {
      state.user.gifts += 1
    },
    decreaseGifts(state) {
      state.user.gifts -= 1
    },
    setIsWithdrawShow(state, b) {
      state.user.isWithdrawShow = b
    },
    setTonConnectUi(state, tcu) {
      state.tonConnectUI = tcu;
    },
    setSubscribe(state) {
      state.user.isSubscribed = true
    },
    async fetchTasks(state) {
      const { data } = await axios.get('/tasks/list')
      state.tasks = data.tasks
    },
    async getConfig() {
      const { data } = await axios.get('/global/config')
      this.commit('setConfig', data)
    },
    async login(state, { initData, startAppValue }) {
      const { data } = await axios.post('/user/auth', {
        telegramInitData: initData,
        referralCode: startAppValue,
      })

      if (data.authToken) {
        this.commit('setAuthToken', data.authToken)
        this.commit('setUser', data.user)
        if (data.created && data.refReward) {
          state.showIntroModal = true
        }
        this.commit('setSiteLoading', false)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
