<template>
  <div class="btn centered" :class="color || 'black'" @click="$emit('on-click')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: ['color']
}
</script>

<style scoped lang="scss">
.btn {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 16px;
  color: #fff;

  &.black {
    background: #303030;
  }

  &.purple {
    background: rgb(145, 27, 145);
  }
}
</style>
