<template>
  <div class="friends centered column">
    <h4 class="page-title">{{ $t('friends_title') }}</h4>

    <div class="bonus">
      <span class="text">{{ $t('friends_description') }}</span>
      <span class="reward">+5,000 SPERM</span>
    </div>

    <Button color="purple" class="invite-btn" @on-click="invite">{{ $t('friends_btn') }}</Button>

    <div class="friends-wrapper">
      <div class="semi">
        <span class="semi-list"> {{ $t('friends_list') }}</span>
        <span class="semi-invited"> {{ $t('friends_invited') }}: <b>{{ $store.state.user.referralTotalInvite
            }}</b></span>
      </div>
      <div class="places" v-if="friends.length">
        <div class="place flex row" v-for="friend in friends" :key="friend.id">
          <div class="user-info centered">
            <div class="avatar flex centered">
              <span>{{ friend.username.substring(0, 1) }}</span>
              <img :src="`https://t.me/i/userpic/160/${friend.telegramLogin}.jpg`" />
            </div>
            <div class="info">
              <span class="name">{{ friend.username }}</span>
              <span class="balance">+ 5,000 SPERM</span>
            </div>
          </div>
        </div>
      </div>
      <div class="no-friends" v-else>
        <span>{{ $t('no_friends') }} <b @click="invite">{{ $t('invite_now') }}</b></span>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';

import axios from 'axios';

export default {
  name: 'FriendsView',
  components: {
    Button
  },
  methods: {
    numberWithCommas,
    invite() {
      const message = this.$i18n.locale === 'ru'
        ? `Присоединяйтесь ко мне на SPERM и давайте зарабатывать вместе! 💸`
        : `Join me on SPERM and let's earn together! 💸`
      const refLink = this.$store.state.config.telegramBotLinkJoin.replace('https://', '') + '?startapp=' + this.$store.state.user.referralCode
      window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${refLink}&text=${message}`)
    },
    async fetchFriends() {
      const { data } = await axios.get('/user/friends')
      this.friends = data.friends
    }
  },
  data() {
    return {
      friends: []
    }
  },
  mounted() {
    this.fetchFriends()
  }
}
</script>

<style lang="scss" scoped>
.friends {
  display: flex;

  .page-title {
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .bonus {
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: space-between;
    background: rgb(158, 158, 158, 0.2);
    margin: 20px 0;
    border-radius: 5px;

    @media (max-width: 407px) {
      padding: 15px;
      font-size: 14px;
    }

    @media (max-width: 360px) {
      padding: 10px;
      font-size: 14px;
    }
  }

  .invite-btn {
    width: 100%;
    font-size: 18px;
    padding: 15px 25px;
  }

  .friends-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;

    .semi {
      display: flex;
      width: 100%;
      margin: 10px 0;
      justify-content: space-between;
      padding: 0 10px;

      .semi-invited {
        b {
          color: #d55cd5;
          font-weight: 800;
        }
      }
    }

    .no-friends {
      margin-top: 20px;

      span {
        color: grey;

        b {
          margin-left: 5px;
          color: #fff;
          background: rgb(145, 27, 145);
          padding: 5px 15px;
          border-radius: 5px;
        }
      }
    }

    .places {
      display: flex;
      gap: 20px 0;
      flex-direction: column;
      width: 100%;
      padding: 0 10px;
      margin-top: 10px;
    }

    .place {
      justify-content: space-between;

      .user-info {
        display: flex;

        .avatar {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background: #6767f7;
          overflow: hidden;
          position: relative;

          span {
            font-size: 22px;
          }

          img {
            max-width: 100%;
            position: absolute;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-left: 10px;

          .name {
            font-size: 16px;
            margin-bottom: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 170px;
            white-space: nowrap;
          }

          .balance {
            font-weight: 700;
            color: #c5c5c5;
          }
        }
      }

      .place-num {
        &.big {
          font-size: 24px;
        }
      }
    }
  }
}
</style>