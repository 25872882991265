<template>
  <div class="tasks centered column">
    <h4 class="page-title">{{ $t('tasks_title') }}</h4>
    <h4 class="page-description">{{ $t('tasks_description') }}</h4>

    <Button color="purple" class="vip-btn" @on-click="checkVip">Проверить VIP подписку</Button>

    <div class="tasks-list">
      <div class="task" v-for="task in $store.state.tasks" :key="task.id" @click="runTask(task)"
        :class="{ 'partner': task.isPartner }">
        <img :src="task.iconUrl" />
        <div class="info">
          <span class="name">{{ task[`${this.$i18n.locale}Title`] }} <span v-if="task.payload.count">({{
            $store.state.user.referralTotalInvite }} / {{ task.payload.count }})</span></span>
          <span class="reward" v-if="task.payload.url === 'https://t.me/+XhpMy-iV_J83ZGYy'">+{{
            numberWithCommas(task.reward) }} SPERM<br />(+1 подарок)</span>
          <span class="reward" v-else-if="!task.isPartner">+{{ numberWithCommas(task.reward) }} SPERM</span>
          <span class="reward" v-else="!task.isPartner">+0.3 TON</span>
        </div>
        <span class="arrow">></span>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas } from '../utility';
import Button from '../components/Button.vue';
import axios from 'axios'

export default {
  name: 'TasksView',
  components: {
    Button
  },
  data() {
    return {
      freeze: false
    }
  },
  methods: {
    numberWithCommas,
    async checkVip() {
      if (this.freeze) {
        return
      }
      this.freeze = true
      await axios.get('/user/check-vip').then(() => {
        this.$store.commit('getBalance')
        alert('Вы получили 5,000,000 SPERM!')
      }).catch(() => {
        alert('Вы не являетесь подписчиком VIP клуба или уже забрали награду')
      })
      this.freeze = false
    },
    async runTask(task) {
      if (task.payload && task.payload.url) {
        if (task.payload.url.indexOf('/t.me/') >= 0) {
          window.Telegram.WebApp.openTelegramLink(task.payload.url)
        } else {
          window.open(task.payload.url)
        }

        setTimeout(() => {
          this.$store.commit('setSelectedTask', task)
        }, 3000)
      } else if (task.payload && task.payload.count) {
        if (this.$store.state.user.referralTotalInvite < task.payload.count) {
          const message = this.$i18n.locale === 'ru'
            ? `Присоединяйтесь ко мне на SPERM и давайте зарабатывать вместе! 💸`
            : `Join me on SPERM and let's earn together! 💸`
          const refLink = this.$store.state.config.telegramBotLinkJoin.replace('https://', '') + '?startapp=' + this.$store.state.user.referralCode
          window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?url=${refLink}&text=${message}`)

          return
        }

        this.$store.commit('setSelectedTask', task)
      }
    }
  },
  data() {
    return {
      tasks: [],
    }
  },
  mounted() {
    this.$store.commit('fetchTasks')
  }
}
</script>

<style lang="scss" scoped>
.tasks {
  display: flex;

  .vip-btn {
    margin: 10px 0;
    width: 100%;
  }

  .page-title {
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }


  .tasks-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
    gap: 15px 0;

    .task {
      display: flex;
      width: 100%;
      position: relative;
      border-radius: 5px;
      padding: 15px;
      background: rgb(158, 158, 158, 0.2);
      align-items: center;
      border: 1px solid transparent;

      &.partner {
        border: 1px solid #2d7fc5bf;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;
        justify-content: flex-start;

        .name {
          font-weight: 600;
        }

        .reward {
          margin-top: 8px;
        }
      }

      .arrow {
        position: absolute;
        font-size: 30px;
        right: 20px;
        font-weight: 200;
        color: #bababa;
      }
    }
  }
}
</style>