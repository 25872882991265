<template>
  <div v-if="!$store.state.siteLoading">
    <div class="viewport">
      <transition name="fade">
        <router-view />
      </transition>
    </div>
    <Footer />
    <Modal v-if="$store.state.showIntroModal" @closeModal="$store.commit('hideIntroModal')" />
    <CompleteTaskModal v-if="$store.state.selectedTask !== null" @closeModal="$store.commit('setSelectedTask', null)" />
    <WithdrawModal v-if="$store.state.isWithdrawShow" @closeModal="$store.commit('setIsWithdrawShow', false)" />
  </div>
</template>


<script>
import Footer from './components/Footer.vue'
import Modal from './components/Modal.vue'
import CompleteTaskModal from './components/CompleteTaskModal.vue'
import WithdrawModal from './components/WithdrawModal.vue';

export default {
  name: 'App',
  components: { Footer, Modal, CompleteTaskModal, WithdrawModal },
  data() {
    return {
      showIntroModal: true
    }
  },
  methods: {
    async init() {
      let { initData } = window.Telegram.WebApp

      this.$store.commit('getConfig')

      const urlParams = new URLSearchParams(window.location.search);
      const startAppValue = urlParams.get('tgWebAppStartParam');

      const tonConnectUI = new TON_CONNECT_UI.TonConnectUI({
        manifestUrl: 'https://bubblewallet.pro/tonconnect-manifest.json',
      })
      tonConnectUI.uiOptions = {
        twaReturnUrl: 'https://t.me/SpermCoinbot/join'
      };
      this.$store.commit('setTonConnectUi', tonConnectUI)

      // const locale = window.Telegram.WebApp.initDataUnsafe.user.language_code;

      // if (locale === 'ru') {
      //   this.$i18n.locale = 'ru'
      // } else {
      //   this.$i18n.locale = 'en'
      // }

      this.$store.commit('login', { initData, startAppValue })
    }
  },
  mounted() {
    try {
      window.Telegram.WebApp.expand()
      window.Telegram.WebApp.disableVerticalSwipes()
      window.Telegram.WebApp.headerColor = "#000"
      window.Telegram.WebApp.backgroundColor = "#000"
    } catch (e) {
      console.log(e)
    }
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.viewport {
  width: 100%;
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 30px 15px 15px 15px;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
