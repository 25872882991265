<template>
  <div class="gift centered">
    <img src="@/assets/images/gift.png" :class="{ 'animated': freeze }" @click="open">
    <span>Нажмите на подарок чтобы получить награду</span>
  </div>
</template>

<script>
import axios from 'axios';

import Button from './Button.vue';
import { numberWithCommas } from '../utility';

export default {
  name: 'Gift',
  components: { Button },
  data() {
    return {
      freeze: false
    }
  },
  methods: {
    numberWithCommas,
    open() {
      if (this.freeze) {
        return
      }

      this.freeze = true
      setTimeout(() => this.$emit('opened'), 2000)
    }
  },
}
</script>

<style scoped lang="scss">
.gift {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);

  img {
    max-width: 100%;

    &.animated {
      animation: hideGift 2s;
      animation-fill-mode: forwards;
    }
  }

  span {
    width: 100%;
    padding: 10px 15px;
    background: black;
  }
}

@keyframes hideGift {
  from {
    transform: rotate(0deg) scale(1)
  }

  50% {
    transform: rotate(25deg) scale(0.8)
  }

  75% {
    transform: rotate(-25deg) scale(0.7)
  }

  to {
    transform: rotate(-25deg) scale(0)
  }
}
</style>
