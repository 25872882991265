<template>
  <div class="leaderboard centered">
    <h4 class="page-title">{{ $t('leaderboard_title') }}</h4>

    <div class="my-place">
      <div class="place flex row">
        <div class="user-info centered">
          <div class="avatar flex centered">
            <span>{{ $store.getters.avatarPlaceholder }}</span>
            <img :src="`https://t.me/i/userpic/160/${$store.state.user.telegramLogin}.jpg`" />
          </div>
          <div class="info">
            <span class="name">{{ $store.state.user.username }}</span>
            <span class="balance">{{ numberWithCommas($store.state.user.balance) }} SPERM</span>
          </div>
        </div>
        <span class="place-num flex centered">#{{ myPlace }}</span>
      </div>
    </div>

    <span class="separator">
      <span class="right">{{ $t('leaderboard_top') }}</span>
    </span>

    <div class="places">
      <div class="place flex row" v-for="(member, i) in list" :key="i">
        <div class="user-info centered">
          <div class="avatar flex centered">
            <span>{{ member.username.substring(0, 1) }}</span>
            <img :src="`https://t.me/i/userpic/160/${member.telegramLogin}.jpg`" />
          </div>
          <div class="info">
            <span class="name">{{ member.username }}</span>
            <span class="balance">{{ numberWithCommas(+member.balance) }} SPERM</span>
          </div>
        </div>
        <span class="place-num flex centered" :class="{ 'big': (i + 1) <= 3 }">#{{ i + 1 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { numberWithCommas } from '../utility';

export default {
  name: 'LeaderboardView',
  components: {
  },
  methods: {
    numberWithCommas,
    getPlaceIcon(place) {
      if (place === 1) {
        return `🥇`
      } else if (place === 2) {
        return `🥈`
      } else if (place === 3) {
        return `🥉`
      } else {
        return `#${place}`
      }
    },
    async fetchLeaderboard() {
      const { data } = await axios.get('/leaderboard/list')
      this.total = data.total
      this.list = data.list
      this.myPlace = data.myPlace
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      myPlace: 0,
    }
  },
  mounted() {
    this.fetchLeaderboard()
  }
}
</script>

<style lang="scss" scoped>
.leaderboard {
  .page-title {
    font-size: 32px;
    font-weight: 800;

    @media (max-width: 386px) {
      font-size: 26px;
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    margin: 30px 0 10px 0;
    font-size: 14px;
    padding: 0 10px;
    color: #b1b1b1;
  }

  .my-place {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 30px;
    background: rgb(98, 98, 98, 0.2);
    border-radius: 5px;
  }

  .places {
    display: flex;
    gap: 20px 0;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
    margin-top: 30px;
  }

  .place {
    justify-content: space-between;

    .user-info {
      display: flex;

      .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: #6767f7;
        overflow: hidden;
        position: relative;

        span {
          font-size: 22px;
        }

        img {
          max-width: 100%;
          position: absolute;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        .name {
          font-size: 16px;
          margin-bottom: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 170px;
          white-space: nowrap;
        }

        .balance {
          font-weight: 700;
          color: #c5c5c5;
        }
      }
    }

    .place-num {
      color: rgb(167, 167, 167);

      &.big {
        color: #fff;
        font-size: 20px;
      }
    }
  }
}
</style>