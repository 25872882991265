<template>
  <div class="home">
    <Header />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
  },
  data() {
  },
}
</script>

<style lang="scss" scoped></style>