import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios';
import { createI18n } from 'vue-i18n'

import './assets/styles/base.scss'

// import { Buffer } from 'buffer';

// // @ts-ignore
// window.Buffer = Buffer;

const app = createApp(App)
app.use(store)

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  messages: {
    en: {
      menu: {
        home: 'Home',
        tasks: 'Tasks',
        friends: 'Friends',
        raiting: 'Raiting',
      },
      my_wallet: 'My wallet',
      coins: 'Coins',

      tasks_title: 'Tasks',
      tasks_description: 'Complete tasks and reach more SPERM',

      friends_title: 'Friends',
      friends_description: 'Invite friend and get',
      friends_list: 'Friends list',
      friends_invited: 'Invited',
      friends_btn: 'Invite friends',

      leaderboard_title: 'Raiting',
      leaderboard_top: 'Top 100',

      invite_now: 'Invite now',
      no_friends: 'No friends.',

      intro_bonus: 'You got 5,000 SPERM bonus!',

      thanks: 'Thanks!',

      withdraw: 'Withdraw funds',
      withdraw_72: 'Withdraw will be available in 72 hours',

      open_gift: 'Open gift',

      task_check_title: 'Complete the task and click on the button "Take reward"',
      task_check_btn: 'Take reward',
    },
    ru: {
      menu: {
        home: 'Главная',
        tasks: 'Задания',
        friends: 'Друзья',
        raiting: 'Рейтинг',
      },
      my_wallet: 'Мой кошелек',
      coins: 'Монеты',

      tasks_title: 'Задания',
      tasks_description: 'Выполняй задания и получай больше SPERM',

      friends_title: 'Друзья',
      friends_description: 'Пригласи друга и получи',
      friends_list: 'Список друзей',
      friends_invited: 'Приглашено',
      friends_btn: 'Пригласить друзей',

      leaderboard_title: 'Рейтинг',
      leaderboard_top: 'Топ 100',

      invite_now: 'Пригласить сейчас',
      no_friends: 'Нет друзей',

      intro_bonus: 'Вы получили 5,000 бонусных SPERM!',

      thanks: 'Спасибо!',

      withdraw: 'Вывод средств',
      withdraw_72: 'Вывод будет доступен через 72 часа',

      open_gift: 'Открыть подарок',

      task_check_title: 'Выполните задание и нажмите на кнопку "Забрать награду"',
      task_check_btn: 'Забрать награду',
    }
  }
})

app.use(i18n)

axios.defaults.withCredentials = true
axios.interceptors.request.use(
  function (config) {
    if (store.state.authToken) {
      config.headers["Auth-Token"] = store.state.authToken
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '/api'
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)

app.use(router).mount('#app')
